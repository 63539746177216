
import { defineComponent } from "vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-5",
  props: {
    widgetClasses: String,
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;
    const journey = [];
    const oqfs = [];

    const branches = [
      { value: "0", label: "Alor Star Branch" },
      { value: "1", label: "Ipoh  Branch" },
      { value: "2", label: "Johor Bahru Branch" },
      { value: "3", label: "KL Main Branch" },
      { value: "4", label: "Kota Bharu Branch" },
      { value: "5", label: "Kota Kinabalu Branch" },
      { value: "6", label: "Kuala Terengganu Branch" },
      { value: "7", label: "Kuantan Branch" },
      { value: "8", label: "Kuching Branch" },
      { value: "9", label: "Melaka Branch" },
      { value: "10", label: "Penang Branch" },
    ];

    const services = [
      { value: "0", label: "PPK : Penyertaan Semula Kali ke 2" },
      { value: "1", label: "PPK: Penarikan diri (< 1 Tahun)" },
      { value: "2", label: "PPK: Permohonan Penjadualan Semula" },
      { value: "3", label: "PPK:Permohonan Penangguhan Bayaran" },
      // { value: "4", label: "URUS/FIRST: Permohonan Penambahan Fasiliti" },
      // {
      //   value: "5",
      //   label:
      //     "URUS:Permohonan Penangguhan Bantuan Banjir(Penangguhan Bayaran)",
      // },
      // { value: "6", label: "URUS:Permohonan Penangguhan bayaran" },
    ];

    const ras = [];
    const journeyRas = [];
    const journeyEDMP = [];
    const journeySDRS = [];
    const journeyEnrollment = [];
    const journeyURUS = [];
    // const journeyEDMP = [
    //   {
    //     date: "29/04/2022",
    //     journeyTitle: "Submitted",
    //     journeyStatus: "New",
    //   },
    // ];

    // const journeySDRS = [
    //   {
    //     date: "18/05/2022",
    //     journeyTitle: "Submitted",
    //     journeyStatus: "New",
    //   },
    // ];

    const voc = {
      totalrows: 0,
      result: [
        {
          voc_datetime: "2021-10-29 10:31:42",
          voc_reference_number: "AKPK21/10/011",
          voc_current_status: "Closed",
        },
        {
          voc_datetime: "2021-10-29 17:45:01",
          voc_reference_number: "AKPKF21/11/017",
          voc_current_status: "Acknowledged",
        },
      ],
    };

    return {
      store,
      cstmr_id,
      oqfs,
      branches,
      services,
      journey,
      voc,
      ras,
      journeyRas,
      journeyEDMP,
      journeySDRS,
      journeyEnrollment,
      journeyURUS
    };
  },
  components: {
    Dropdown1,
  },
  methods: {
    init() {

      // RA FORMS
      this.store.dispatch(Actions.GET_RA_FORMS, this.cstmr_id).then(() => {
        let raForms = this.store.getters.getRaFormsData;

        for (let index = 0; index < raForms.length; index++) {
          let date = moment(raForms[index].created_at).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          let journeyTitle = "Submitted RA Form";
          let journeyDes = "Reason : " + raForms[index].raw.reason;
          let journeyMoreDes = "Type :" + raForms[index].raw.type;
          let journeyStatus = raForms[index].status;

          this.journeyRas.push({
            date: date,
            journeyTitle: journeyTitle,
            journeyDes: journeyDes,
            journeyMoreDes: journeyMoreDes,
            journeyStatus: journeyStatus,
          });
        }
      });


      // DMP Resulotions
      this.store.dispatch(Actions.GET_DMP_RESOLUTIONS, this.cstmr_id).then(() => {
        let dmpResolutions = this.store.getters.getDMPResolutions;

        for (let index = 0; index < dmpResolutions.length; index++) {
          let date = moment(dmpResolutions[index].created_at).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          let journeyTitle = "Submitted DMP Form";
          let journeyDes = "Reason : " + dmpResolutions[index].raw.reason;
          let journeyMoreDes = "Type :" + dmpResolutions[index].raw.type;
          let journeyStatus = dmpResolutions[index].status;

          this.journeyEDMP.push({
            date: date,
            journeyTitle: journeyTitle,
            journeyDes: journeyDes,
            journeyMoreDes: journeyMoreDes,
            journeyStatus: journeyStatus,
          });
        }
      });

      //OQF
      this.store.dispatch(Actions.GET_OQFS, this.cstmr_id).then(() => {
        this.oqfs = this.store.getters.getOqfsData;

        for (let index = 0; index < this.oqfs.length; index++) {
          let date = moment(this.oqfs[index].created_at).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          let journeyTitle = "Submitted Online Query Form";
          let journeyDes = "";
          let journeyMoreDes = "";
          let branch = "";
          let service = "";

          switch (this.oqfs[index].reason) {
            case "increase-instalment":
              journeyDes = "Increase Instalment";
              journeyMoreDes = "Remarks : " + this.oqfs[index].remarks;
              branch = this.getBranch(index);
              service = this.getService(index);
              break;
            case "Postponement":
              journeyDes = "Postponement";
              journeyMoreDes = "Remarks : " + this.oqfs[index].remarks;
              branch = this.getBranch(index);
              service = this.getService(index);
              break;
            case "WithdrawalDMP_highLoanCommitment":
              journeyDes = "Withdrawal in order to join the DMP";
              journeyMoreDes = "Reason : High Loan Commitment";
              branch = "";
              service = "";
              break;

            case "WithdrawalDMP_holisticLoanRestructurint":
              journeyDes = "Withdrawal in order to join the DMP";
              journeyMoreDes = "Reason : Holistic Loan restructuring";
              branch = "";
              service = "";
              break;

            case "WithdrawalDMP_nonPerformingLoan":
              journeyDes = "Withdrawal in order to join the DMP";
              journeyMoreDes = "Reason : Non-Performing Loan";
              branch = "";
              service = "";
              break;

            case "withdrawalFI_theFinancialSituationHasImproved":
              journeyDes =
                "Withdraw in order to deal direct with the Financial Instituition";
              journeyMoreDes = "Reason : The financial situation has improved";
              branch = "";
              service = "";
              break;

            case "withdrawalFI_fisSSpecialRepaymentPackage":
              journeyDes =
                "Withdraw in order to deal direct with the Financial Instituition";
              journeyMoreDes = "Reason : FIS's Special Repayment Package";
              branch = "";
              service = "";
              break;

            case "withdrawalFI_removeCcrisTagging":
              journeyDes =
                "Withdraw in order to deal direct with the Financial Instituition";
              journeyMoreDes = "Reason : Remove CCRIS Tagging";
              branch = "";
              service = "";
              break;

            case "withdrawalFI_applyForANewLoanApplication":
              journeyDes =
                "Withdraw in order to deal direct with the Financial Instituition";
              journeyMoreDes = "Reason : Apply for a new loan application";
              branch = "";
              service = "";
              break;

            default:
              break;
          }

          this.journey.push({
            date: date,
            journeyTitle: journeyTitle,
            journeyDes: journeyDes,
            journeyMoreDes: journeyMoreDes,
            journeyBranch: branch,
            journeyService: service,
          });
        }
      });

      // ENROLLMENT DETIALS
      this.store.dispatch(Actions.GET_ENROLLMENT_DETAILS, this.cstmr_id).then(() => {
        let enrollmentDetails = this.store.getters.getEnrollmentDetailsData;
        let enrollmentCourse = enrollmentDetails.EnrolledCourses;

        for (let index = 0; index < enrollmentCourse.length; index++) {
          let date = moment(enrollmentCourse[index].EnrolledDate).format(
            "DD/MM/YYYY HH:mm:ss"
          );
          let journeyTitle = enrollmentCourse[index].Title;
          let journeyCat = enrollmentCourse[index].Category;
          let journeyCode = enrollmentCourse[index].Code;
          let journeyStatus = enrollmentCourse[index].Status;

          let dataMap = {
            date: date,
            journeyTitle: journeyTitle,
            journeyCat: journeyCat,
            journeyCode: journeyCode,
            journeyStatus: journeyStatus,
          };

          if(journeyCat === 'URUS'){
            this.journeyURUS.push(dataMap)
          }
          this.journeyEnrollment.push(dataMap);
        }

        if(this.journeyURUS.length > 0){
          this.$emit("updateJourneryURUS", this.journeyURUS);
        }
      });
    },

    getBranch(index) {
      let branch = "";
      for (let i = 0; i < this.branches.length; i++) {
        if (this.oqfs[index].organisation_id == this.branches[i].value) {
          branch = this.branches[i].label;
        }
      }
      return branch;
    },

    getService(index) {
      let service = "";
      for (let i = 0; i < this.services.length; i++) {
        if (this.oqfs[index].services == this.services[i].value) {
          service = this.services[i].label;
        }
      }
      return service;
    },
  },
  async mounted() {
    this.init();
  },
});
