import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "row gy-5 g-xl-8" }
const _hoisted_4 = { class: "col-lg-12" }
const _hoisted_5 = { class: "row mt-5" }
const _hoisted_6 = { class: "col-xl-12" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AKPKSurveyJourney = _resolveComponent("AKPKSurveyJourney")!
  const _component_WidgetTrackAndMonitoring = _resolveComponent("WidgetTrackAndMonitoring")!
  const _component_WidgetCustomerJourney = _resolveComponent("WidgetCustomerJourney")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_AKPKSurveyJourney)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_WidgetTrackAndMonitoring, {
          cstmr_id: _ctx.cstmr_id,
          journeyURUS: _ctx.journeyURUS
        }, null, 8, ["cstmr_id", "journeyURUS"])
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_WidgetCustomerJourney, {
          "widget-classes": "mb-5",
          onUpdateJourneryURUS: _ctx.updateJourneryURUS
        }, null, 8, ["onUpdateJourneryURUS"])
      ])
    ])
  ], 64))
}