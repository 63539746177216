
import { defineComponent } from "vue";
// import KTActivityItem1 from "./activity-timeline/Item1.vue";
import moment from "moment";

import { Form, Field, ErrorMessage } from "vee-validate";

import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "activity",
  components: {
    // KTActivityItem1,
    // Form,
    // Field,
  },
  data() {
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const appointments = [];

    return {
      sessionDateTime: moment().format("YYYY-MM-DD hh:mm:ss"),
      store: useStore(),
      route,
      router,
      cstmr_id,
      isNewAppointment: false,
      appointments,
      appointmentsReady: false,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_APPOINTMENTS, this.cstmr_id).then(() => {
        this.appointments = this.store.getters.getAppointnments;
        this.appointmentsReady = true;
      });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  async mounted() {
    this.init();
  },
});
