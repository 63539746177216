
import { defineComponent, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "kt-widget-track-and-monitoring",
  components: {},
  props: ["cstmr_id", "journeyURUS"],
  setup(props){
    const listJourneyURUS = computed(() => {
      return props.journeyURUS;
    });

    return {
      listJourneyURUS
    }
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const pfpMilestones = {
      start: { date: "" },
      join: { date: "" },
      report: { date: "", url: "", status: "" },
      cashflow1: { date: "", url: "" },
      cashflow2: { date: "", url: "" },
      review1: { date: "" },
      report2: { date: "", url: "" },
      survey: { date: "" },
      end: { date: "" },
    };

    const pfpModule = {
      start: { date: "" },
      module1: { date: "" },
      module2: { date: "" },
      module3: { date: "" },
      module4: { date: "" },
      module5: { date: "" },
      module6: { date: "" },
      module7: { date: "" },
      module8: { date: "" },
      assessing: { date: "" },
      end: { date: "" },
    };
    return {
      store,
      route,
      router,
      pfpMilestones,
      pfpModule,
    };
  },
  methods: {
    init() {

      setTimeout(() => {
        let customer = this.store.getters.getCustomerData;

        if(customer.slots.length > 0) {
          for (let index = 0; index < customer.slots.length; index++) {
            if(customer.slots[index].slot_type == "assignment") {
              this.pfpModule.start.date = customer.slots[index].appointment_date;
              console.log(this.pfpModule)
            }
          }

        }
      },100)

      this.store.dispatch(Actions.GET_PFPS, this.cstmr_id).then(() => {
        let pfps = this.store.getters.getPfpsData;

        for (let index = 0; index < pfps.length; index++) {
          if (
            pfps[index].status == "PFP Report Generated" ||
            pfps[index].status == "PFP Report Incomplete"
          ) {
            this.pfpMilestones.start.date = this.getHumanDate(
              pfps[index].created_at
            );
            this.pfpMilestones.join.date = this.getHumanDate(
              pfps[index].created_at
            );
            this.pfpMilestones.report.date = this.getHumanDate(
              pfps[index].created_at
            );
            this.pfpMilestones.report.status = pfps[index].status;
          }

          if (pfps[index].type == "PFP Module") {
            switch (pfps[index].status) {
              case "Start":
                this.pfpModule.start.date = this.getHumanDate(
                  pfps[index].created_at
                );
                break;

              case "Module 1":
                this.pfpModule.module1.date = this.getHumanDate(
                  pfps[index].created_at
                );
                break;

              case "Module 2":
                this.pfpModule.module2.date = this.getHumanDate(
                  pfps[index].created_at
                );
                break;

              case "Module 3":
                this.pfpModule.module3.date = this.getHumanDate(
                  pfps[index].created_at
                );
                break;

              default:
                break;
            }
          }
        }
      });
    },
    getHumanDate(date) {
      return moment(date).format("DD/MM/YYYY h:mm:ss a");
    },
  },
  async mounted() {
    this.init();
    // console.log(this.customer);
  },
});
