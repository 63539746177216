
import { defineComponent, onMounted } from "vue";
import WidgetCustomerJourney from "@/components/widgets/WidgetCustomerJourney.vue";
import KTTableWidgetAppointments from "@/components/widgets/tables/WidgetAppointments.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import WidgetTrackAndMonitoring from "@/components/widgets/tables/WidgetTrackAndMonitoring.vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import AKPKSurveyJourney from "./AKPKSurveyJourney.vue";

export default defineComponent({
  name: "customers-overview",
  props: ["oqfs"],
  components: {
    WidgetCustomerJourney,
    // KTTableWidgetAppointments,
    WidgetTrackAndMonitoring,
    AKPKSurveyJourney
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Customer"]);
    });
  },
  data() {
    const route = useRoute();
    const cstmr_id = route.params.id ?? null;

    return {
      store: useStore(),
      cstmr_id,
      journeyURUS: [],
    };
  },
  methods: {
    init() {
      // this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
      //   this.customer = this.store.getters.getCustomerData;
      //   this.isLoading = false;
      // });
    },
    updateJourneryURUS(journeyURUS) {
      this.journeyURUS = journeyURUS;
    },
  },
  async mounted() {
    this.init();
  },
});
